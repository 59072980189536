import React, { useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import Base from "../../components/global/base";
import $ from "jquery";
import { JSEncrypt } from "jsencrypt";
import API_BASE_URL from "../../services/other/api_settings";
import { PUBLIC_KEY } from "../../services/other/crypto_settings_loader";

const Register = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { login, getInfo } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");
    setIsLoading(true);

    if (username === "") {
      setErrorMessage("Username is required");
      setIsLoading(false);
    } else if (password === "") {
      setErrorMessage("Password is required");
      setIsLoading(false);
    } else if (password !== confirmedPassword) {
      setErrorMessage("Password and confirm password do not match");
      setIsLoading(false);
    } else {
      // Encrypt the password before sending
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(PUBLIC_KEY);
      const encryptedPassword = encrypt.encrypt(password);

      $.ajax({
        url: `${API_BASE_URL}/user/account/register/`,
        type: "POST",
        data: {
          username,
          password: encryptedPassword,
          confirmedPassword: encryptedPassword,
        },
        dataType: "json",
        success: async (resp) => {
          if (resp.error_message === "success") {
            try {
              // Use the original password for login
              await login(username, encryptedPassword);
              await getInfo(localStorage.getItem("jwt_token"));
              navigate("/");
            } catch (error) {
              console.error("Login after registration failed:", error);
              setErrorMessage(
                "Registration successful, but login failed. Please try logging in."
              );
            }
          } else {
            setErrorMessage(resp.error_message);
          }
          setIsLoading(false);
        },
        error(resp) {
          setErrorMessage("Registration failed. Please try again.");
          setIsLoading(false);
        },
      });
    }
  };

  return (
    <Base>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6">
            <h2 className="mb-4">Join Shares Manager</h2>
            <p className="lead">
              Create your account and start managing your stocks today!
            </p>
            <ul className="list-group list-group-flush mb-4">
              <li className="list-group-item">Track your favorite stocks</li>
              <li className="list-group-item">
                Access powerful stock analysis algorithms
              </li>
              <li className="list-group-item">
                Receive personalized stock recommendations
              </li>
              <li className="list-group-item">
                Join a community of like-minded investors
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <div className="card shadow">
              <div className="card-body">
                <h3 className="card-title text-center mb-4">
                  Create Your Account
                </h3>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="username" className="form-label">
                      Username
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder="Choose a unique username"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Create a strong password"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="confirmedPassword" className="form-label">
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="confirmedPassword"
                      value={confirmedPassword}
                      onChange={(e) => setConfirmedPassword(e.target.value)}
                      placeholder="Confirm your password"
                      required
                    />
                  </div>
                  {errorMessage && (
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  )}
                  <div className="d-grid">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Registering...
                        </>
                      ) : (
                        "Create Account"
                      )}
                    </button>
                  </div>
                </form>
                <p className="mt-3 text-center">
                  Already have an account? <Link to="/login">Log in</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default Register;
