import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaTwitter, FaLinkedin, FaEnvelope } from "react-icons/fa";

const BottomBar = () => {
  return (
    <footer className="bg-dark text-light py-4 mt-5 bottom-bar">
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-3 mb-md-0">
            <h5>Contact Us</h5>
            <p>
              <FaEnvelope className="me-2" /> info@sharesmanager.com
            </p>
            <p>5 Martin Pl, Sydney CBD, Sydney, NSW, AU</p>
          </div>
          <div className="col-md-4 mb-3 mb-md-0">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/about" className="text-light">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/faq" className="text-light">
                  FAQ
                </Link>
              </li>
              <li>
                <Link to="/terms" className="text-light">
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="text-light">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <h5>Follow Us (N/A)</h5>
            <div className="d-flex">
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-light me-3"
              >
                <FaFacebook size={24} />
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-light me-3"
              >
                <FaTwitter size={24} />
              </a>
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-light"
              >
                <FaLinkedin size={24} />
              </a>
            </div>
          </div>
        </div>
        <hr className="mt-4 mb-3" />
        <div className="text-center">
          <p>
            &copy; 2024 Shares Manager by LYC Consulting Group. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default BottomBar;
