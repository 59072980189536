import React, { useState, useContext, useCallback } from "react";
import { message, Button, Space, Modal, Input } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import EnhancedTable from "../../components/admin/EnhancedTable";
import {
  getStockList,
  updateStockPopularity,
  deleteStock,
  getLastUpdatedTimes,
  addStock,
} from "../../services/admin/stockApi";
import AddItemModal from "../../components/admin/AddItemModal";
import { AuthContext } from "../../contexts/AuthContext";

const { confirm } = Modal;

const StockManagement = () => {
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isLastUpdatedModalVisible, setIsLastUpdatedModalVisible] =
    useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [selectedStock, setSelectedStock] = useState(null);
  const [popularityRating, setPopularityRating] = useState("");
  const [lastUpdatedTimes, setLastUpdatedTimes] = useState([]);
  const [addStockError, setAddStockError] = useState(null);
  const [tableKey, setTableKey] = useState(0); // New state to force table refresh

  const { darkMode } = useContext(AuthContext);

  const showDeleteConfirm = useCallback(
    (stockId) => {
      confirm({
        title: "Are you sure you want to delete this stock?",
        icon: <ExclamationCircleOutlined />,
        content: "This action cannot be undone.",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          handleDeleteStock(stockId);
        },
        className: darkMode ? "dark-mode" : "",
        maskClosable: true,
        okButtonProps: { className: darkMode ? "dark-mode" : "" },
        cancelButtonProps: { className: darkMode ? "dark-mode" : "" },
      });
    },
    [darkMode]
  );

  const handleDeleteStock = async (stockId) => {
    try {
      await deleteStock(stockId);
      message.success("Stock deleted successfully");
      setTableKey((prevKey) => prevKey + 1); // Force table to refresh
    } catch (error) {
      message.error("Failed to delete stock");
    }
  };

  const columns = [
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
      sorter: true,
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      sorter: true,
    },
    {
      title: "Popularity Rating",
      dataIndex: "popularityRating",
      key: "popularityRating",
      sorter: true,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => handleEditPopularity(record)}>
            Edit Popularity Rating
          </Button>
          <Button onClick={() => handleShowLastUpdated(record.symbol)}>
            Show Last Updated
          </Button>
          <Button danger onClick={() => showDeleteConfirm(record.id)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const fetchData = useCallback(
    async ({ pagination, filters, sorter, searchText }) => {
      try {
        const response = await getStockList({
          page: pagination.current,
          pageSize: pagination.pageSize,
          searchText,
          sortField: sorter.field,
          sortOrder: sorter.order,
        });

        if (response && Array.isArray(response.stocks)) {
          return {
            data: response.stocks,
            total: response.total,
          };
        } else {
          message.error("Invalid data format received from server");
          return { data: [], total: 0 };
        }
      } catch (error) {
        message.error("Failed to fetch stocks");
        return { data: [], total: 0 };
      }
    },
    []
  );

  const handleEditPopularity = useCallback((stock) => {
    setSelectedStock(stock);
    setPopularityRating(stock.popularityRating.toString());
    setIsEditModalVisible(true);
  }, []);

  const handleUpdatePopularity = async () => {
    try {
      const intPopularityRating = Math.round(parseFloat(popularityRating));
      await updateStockPopularity(selectedStock.id, intPopularityRating);
      message.success("Stock popularity updated successfully");
      setIsEditModalVisible(false);
      setTableKey((prevKey) => prevKey + 1); // Force table to refresh
    } catch (error) {
      message.error("Failed to update stock popularity");
    }
  };

  const handleShowLastUpdated = useCallback(async (symbol) => {
    try {
      const times = await getLastUpdatedTimes(symbol);
      setLastUpdatedTimes(
        times.map((item) => ({
          ...item,
          interval: item.table_name.split("_").pop().toUpperCase(),
          symbol: symbol,
        }))
      );
      setIsLastUpdatedModalVisible(true);
    } catch (error) {
      message.error("Failed to fetch last updated times");
    }
  }, []);

  const handleAddStock = async (values) => {
    try {
      await addStock(values);
      message.success("Stock added successfully");
      setIsAddModalVisible(false);
      setAddStockError(null);
      setTableKey((prevKey) => prevKey + 1); // Force table to refresh
    } catch (error) {
      setAddStockError(error.message);
    }
  };

  const addStockFields = [
    { name: "symbol", label: "Symbol", type: "text" },
    { name: "companyName", label: "Company Name", type: "text" },
    { name: "popularityRating", label: "Popularity Rating", type: "number" },
  ];

  const addStockButton = (
    <Button onClick={() => setIsAddModalVisible(true)} type="primary">
      Add Stock
    </Button>
  );

  return (
    <div className="stock-management">
      <h2>Stock Management</h2>
      <EnhancedTable
        key={tableKey}
        columns={columns}
        fetchData={fetchData}
        updateItem={updateStockPopularity}
        deleteItem={handleDeleteStock}
        searchPlaceholder="Search stocks"
        rightAlignedButton={addStockButton}
      />
      <Modal
        title="Edit Popularity Rating"
        open={isEditModalVisible}
        onOk={handleUpdatePopularity}
        onCancel={() => setIsEditModalVisible(false)}
        className={darkMode ? "dark-mode" : ""}
      >
        <Input
          type="number"
          value={popularityRating}
          onChange={(e) => setPopularityRating(e.target.value)}
          placeholder="Enter new popularity rating"
        />
      </Modal>
      <Modal
        title="Last Updated Times"
        open={isLastUpdatedModalVisible}
        onCancel={() => setIsLastUpdatedModalVisible(false)}
        footer={null}
        className={darkMode ? "dark-mode" : ""}
      >
        <table>
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Interval</th>
              <th>Last Update</th>
            </tr>
          </thead>
          <tbody>
            {lastUpdatedTimes.map((item, index) => (
              <tr key={index}>
                <td>{item.symbol}</td>
                <td>{item.interval}</td>
                <td>{new Date(item.last_update).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal>
      <AddItemModal
        visible={isAddModalVisible}
        onCancel={() => {
          setIsAddModalVisible(false);
          setAddStockError(null);
        }}
        onAdd={handleAddStock}
        fields={addStockFields}
        error={addStockError}
      />
    </div>
  );
};

export default StockManagement;
