// crypto_settings_loader.js

let cryptoSettings;

try {
  cryptoSettings = require('./crypto_settings_local.js');
} catch (e) {
  cryptoSettings = require('./crypto_settings.js');
}

export const { PUBLIC_KEY } = cryptoSettings;