import React, { useState, useContext } from "react";
import {
  FaPlus,
  FaTrash,
  FaChevronDown,
  FaChevronUp,
  FaList,
} from "react-icons/fa";
import API_BASE_URL from "../../services/other/api_settings";
import { AuthContext } from "../../contexts/AuthContext";
import "../../css/algorithms/WatchListAccordion.css";
import { message } from "antd";

const WatchListAccordion = ({
  watchLists,
  setWatchLists,
  activeWatchList,
  setActiveWatchList,
  onDeleteWatchList,
  onRemoveStock,
  user,
  onSelectStock,
}) => {
  const [newWatchListName, setNewWatchListName] = useState("");
  const [openListId, setOpenListId] = useState(null);
  const { darkMode } = useContext(AuthContext);

  const createWatchList = () => {
    if (!newWatchListName.trim()) return;

    fetch(`${API_BASE_URL}/api/watchlists`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ userId: user.id, name: newWatchListName }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.text().then((text) => {
            throw new Error(text);
          });
        }
        return response.json();
      })
      .then((newList) => {
        const updatedWatchLists = [...watchLists, newList];
        setWatchLists(updatedWatchLists);
        setNewWatchListName("");
        setOpenListId(newList.id);
        setActiveWatchList(newList);
        message.success("Watch list created successfully!");
      })
      .catch((error) => {
        console.error("Error creating watch list:", error);
        if (error.message === "Watch list exists") {
          message.error("A watch list with this name already exists.");
        } else {
          message.error("Failed to create watch list. Please try again.");
        }
      });
  };

  const toggleList = (listId) => {
    setOpenListId(openListId === listId ? null : listId);
    setActiveWatchList(watchLists.find((list) => list.id === listId));
  };

  return (
    <div className={`watch-list-accordion ${darkMode ? "dark-mode" : ""}`}>
      <h2>Watch Lists</h2>
      <div className="accordion">
        {watchLists.map((list) => (
          <div key={list.id} className="accordion-item">
            <button
              className={`accordion-button ${
                openListId === list.id ? "active" : ""
              }`}
              onClick={() => toggleList(list.id)}
            >
              <FaList className="list-icon" />
              <span>{list.name}</span>
              {openListId === list.id ? <FaChevronUp /> : <FaChevronDown />}
            </button>
            <div
              className={`accordion-body ${
                openListId === list.id ? "open" : ""
              }`}
            >
              <ul className="list-group">
                {list.stocks &&
                  list.stocks.map((stock) => (
                    <li
                      key={stock.id}
                      className="list-group-item"
                      onClick={() => onSelectStock(stock)}
                    >
                      <div className="stock-info">
                        <span className="stock-symbol">{stock.symbol}</span>
                        <span className="company-name">
                          {stock.companyName}
                        </span>
                      </div>
                      <div className="delete-icon-wrapper">
                        <FaTrash
                          className="delete-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            onRemoveStock(list.id, stock.id);
                          }}
                        />
                      </div>
                    </li>
                  ))}
              </ul>
              <button
                className="delete-watchlist-btn"
                onClick={() => onDeleteWatchList(list.id)}
              >
                Delete Watch List
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="create-new-watchlist">
        <input
          type="text"
          placeholder="New Watch List Name"
          value={newWatchListName}
          onChange={(e) => setNewWatchListName(e.target.value)}
        />
        <button onClick={createWatchList} className="create-btn">
          <FaPlus /> Create New Watch List
        </button>
      </div>
    </div>
  );
};

export default WatchListAccordion;
