import React from "react";
import "../../css/algorithms/StockAnalysisDisplay.css";

const StockAnalysisDisplay = ({ signalCounts, timestamp }) => {
  // signalCounts.buy = 7;
  // signalCounts.sell = 3;
  // signalCounts.neutral = 6;
  const total = signalCounts.buy + signalCounts.sell + signalCounts.neutral;
  const buyPercentage = (signalCounts.buy / total) * 100;
  const sellPercentage = (signalCounts.sell / total) * 100;
  const calcPercentage = ((signalCounts.buy - signalCounts.sell) / total) * 100;

  let rotation, summary;
  if (buyPercentage > sellPercentage) {
    // rotation = 90 - buyPercentage * 1.8;
    // rotation = buyPercentage * 1.8;
    if (buyPercentage > 45) {
      summary = "Strong buy";
    } else {
      summary = "Buy";
    }
  } else if (sellPercentage > buyPercentage) {
    // rotation = -90 + sellPercentage * 1.8;
    // rotation = -sellPercentage * 1.8;
    if (sellPercentage > 45) {
      summary = "Strong sell";
    } else {
      summary = "Sell";
    }
  } else {
    rotation = 0;
    summary = "Neutral";
  }

  rotation = calcPercentage * 1.8;

  return (
    <div className="stock-analysis-container">
      <div className="gauge-container">
        <svg className="gauge-svg" viewBox="0 0 200 120">
          <defs>
            <linearGradient
              id="gaugeGradient"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
            >
              <stop offset="0%" stopColor="#ff4757" />
              <stop offset="50%" stopColor="#ffa502" />
              <stop offset="100%" stopColor="#2ed573" />
            </linearGradient>
          </defs>
          <path
            d="M10 110 A90 90 0 0 1 190 110"
            stroke="url(#gaugeGradient)"
            strokeWidth="10"
            fill="none"
          />
        </svg>
        <div
          className="needle"
          style={{ transform: `rotate(${rotation}deg)` }}
        ></div>
        <div className="gauge-labels">
          <span
            className="label"
            style={{ left: "-10%", top: "75%", transform: "translateX(-50%)" }}
          >
            Strong sell
          </span>
          <span
            className="label"
            style={{ left: "10%", top: "30%", transform: "translateX(-50%)" }}
          >
            Sell
          </span>
          <span
            className="label"
            style={{ left: "50%", top: "10%", transform: "translateX(-50%)" }}
          >
            Neutral
          </span>
          <span
            className="label"
            style={{ left: "90%", top: "30%", transform: "translateX(-50%)" }}
          >
            Buy
          </span>
          <span
            className="label"
            style={{ right: "-10%", top: "75%", transform: "translateX(50%)" }}
          >
            Strong buy
          </span>
        </div>
        <div className="summary-text">{summary}</div>
      </div>
      <div className="counts-container">
        <div className="count-item">Buy: {signalCounts.buy}</div>
        <div className="count-item">Sell: {signalCounts.sell}</div>
        <div className="count-item">Neutral: {signalCounts.neutral}</div>
        <div className="count-item">Timestamp: {signalCounts.timestamp}</div>
      </div>
    </div>
  );
};

export default StockAnalysisDisplay;
