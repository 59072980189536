import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Base from "../../components/global/base";
import { FaChartBar, FaClock, FaStar, FaChartLine } from "react-icons/fa";
import $ from "jquery";
import API_BASE_URL from "../../services/other/api_settings";

const Home = () => {
  const [popularStocks, setPopularStocks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchStocks = useCallback((query) => {
    return new Promise((resolve, reject) => {
      const fullUrl = `${API_BASE_URL}/api/stock_symbols`;
      $.ajax({
        url: fullUrl,
        type: "get",
        data: { query },
        success(resp) {
          resolve(resp);
        },
        error(resp) {
          reject(resp);
        },
      });
    });
  }, []);

  const fetchPopularStocks = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      const stocks = await fetchStocks("");
      if (Array.isArray(stocks)) {
        setPopularStocks(stocks.slice(0, 4)); // Get top 4 popular stocks
      } else {
        throw new Error("Received invalid data format");
      }
    } catch (error) {
      console.error("Error fetching popular stocks:", error);
      setError("Failed to fetch popular stocks. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  }, [fetchStocks]);

  useEffect(() => {
    fetchPopularStocks();
  }, [fetchPopularStocks]);

  return (
    <Base>
      <div className="container mt-4">
        {/* Hero Section */}
        <section className="jumbotron text-center py-5 mb-5">
          <h1 className="display-4 mb-3">Welcome to Shares Manager</h1>
          <p className="lead mb-4">
            Analyze buy/sell ratios, track your favorite stocks, and discover
            trending investments.
          </p>
          <br></br>
          <p className="lead mb-4">
            (Currently in closed, invite only beta, non-commerical, only for
            personal use)
          </p>
          <Link to="/register" className="btn btn-primary btn-lg">
            Get Started
          </Link>
        </section>

        {/* Key Features */}
        <section className="my-5">
          <h2 className="mb-4 text-center">Our Features</h2>
          <div className="row">
            <div className="col-md-3 mb-3">
              <div className="card h-100 text-center">
                <div className="card-body">
                  <FaChartBar className="feature-icon mb-3" size={48} />
                  <h5 className="card-title">Buy/Sell Ratio Analysis</h5>
                  <p className="card-text">
                    View the buy/sell ratios for any stock in our database
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="card h-100 text-center">
                <div className="card-body">
                  <FaClock className="feature-icon mb-3" size={48} />
                  <h5 className="card-title">Flexible Time Intervals</h5>
                  <p className="card-text">
                    Analyze stocks over various time periods for comprehensive
                    insights
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="card h-100 text-center">
                <div className="card-body">
                  <FaStar className="feature-icon mb-3" size={48} />
                  <h5 className="card-title">Follow Favorite Stocks</h5>
                  <p className="card-text">
                    Keep track of the stocks that matter most to you
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="card h-100 text-center">
                <div className="card-body">
                  <FaChartLine className="feature-icon mb-3" size={48} />
                  <h5 className="card-title">Popularity Metrics</h5>
                  <p className="card-text">
                    Discover which stocks are trending among our users
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* How It Works */}
        <section className="my-5 bg-light p-4 rounded">
          <h2 className="mb-4 text-center">How It Works</h2>
          <div className="row">
            <div className="col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">1. Select a Stock</h5>
                  <p className="card-text">
                    Choose any stock from our extensive database to analyze.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">2. Choose Time Interval</h5>
                  <p className="card-text">
                    Select your preferred time interval for the analysis.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">3. View Results</h5>
                  <p className="card-text">
                    Get instant buy/sell ratio results and make informed
                    decisions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Popular Stocks Section */}
        <section className="my-5">
          <h2 className="mb-4 text-center">Popular Stocks</h2>
          {isLoading ? (
            <p className="text-center">Loading popular stocks...</p>
          ) : error ? (
            <p className="text-center text-danger">{error}</p>
          ) : (
            <div className="row">
              {popularStocks.length > 0 ? (
                popularStocks.map((stock, index) => (
                  <div className="col-md-3 mb-3" key={index}>
                    <div className="card">
                      <div className="card-body">
                        <h5 className="card-title">{stock.symbol}</h5>
                        <p className="card-text">{stock.companyName}</p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-center">
                  No popular stocks available at the moment.
                </p>
              )}
            </div>
          )}
        </section>

        {/* Call to Action */}
        <section className="my-5 text-center">
          <h2 className="mb-4">Ready to Start Analyzing?</h2>
          <p className="lead">
            Join Shares Manager today and gain valuable insights into stock
            buy/sell ratios.
          </p>
          <Link to="/register" className="btn btn-primary btn-lg">
            Create Your Free Account
          </Link>
        </section>
      </div>
    </Base>
  );
};

export default Home;
