import React from "react";
import "../../css/algorithms/SignalDetailedDisplay.css";

const SignalDetailedDisplay = ({ signals }) => {
  return (
    <div className="signal-detailed-container">
      <h3>Signal Details</h3>
      <div className="signal-list">
        {signals.map((signal, index) => (
          <div
            key={index}
            className={`signal-item ${signal.type.toLowerCase()}`}
          >
            <div className="signal-info">
              <span className="indicator-name">{signal.indicator}</span>
              <span className="signal-type">{signal.type}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SignalDetailedDisplay;
