import $ from 'jquery';
import API_BASE_URL from "../other/api_settings";

const getAuthHeader = () => {
  const token = localStorage.getItem("jwt_token");
  return token ? { Authorization: "Bearer " + token } : {};
};

export const getUserList = ({ page, pageSize, searchText, sortField, sortOrder }) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${API_BASE_URL}/api/admin/users`,
      type: "GET",
      headers: getAuthHeader(),
      data: {
        page,
        pageSize,
        searchText,
        sortField,
        sortOrder,
      },
      success(response) {
        // console.log(response);
        resolve(response);
      },
      error(error) {
        // console.log(error);
        reject(error);
      }
    });
  });
};

export const updateUser = (userId, userData) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${API_BASE_URL}/api/admin/manage/${userId}`,
      type: "PUT",
      headers: getAuthHeader(),
      data: JSON.stringify(userData),
      contentType: "application/json",
      success(response) {
        resolve(response);
      },
      error(error) {
        reject(error);
      }
    });
  });
};

export const deleteUser = (userId) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${API_BASE_URL}/api/admin/manage/${userId}`,
      type: "DELETE",
      headers: getAuthHeader(),
      success(response) {
        resolve(response);
      },
      error(error) {
        reject(error);
      }
    });
  });
};

export const addUser = (userData) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${API_BASE_URL}/api/admin/manage/create`,
      type: "POST",
      headers: getAuthHeader(),
      data: JSON.stringify(userData),
      contentType: "application/json",
      success(response) {
        resolve(response);
      },
      error(error) {
        if (error.responseJSON && error.responseJSON.message) {
          reject(new Error(error.responseJSON.message));
        } else if (error.status === 409) {
          reject(new Error("User with this username or email already exists"));
        } else {
          reject(new Error("An error occurred while adding the user"));
        }
      }
    });
  });
};

export const getAppSettings = () => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${API_BASE_URL}/api/admin/settings`,
      type: "GET",
      headers: getAuthHeader(),
      success(response) {
        resolve(response);
      },
      error(error) {
        reject(error);
      }
    });
  });
};

export const updateAppSettings = (settings) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${API_BASE_URL}/api/admin/settings`,
      type: "PUT",
      headers: getAuthHeader(),
      data: JSON.stringify(settings),
      contentType: "application/json",
      success(response) {
        resolve(response);
      },
      error(error) {
        reject(error);
      }
    });
  });
};

export const getDashboardData = () => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${API_BASE_URL}/api/admin/dashboard`,
      type: "GET",
      headers: getAuthHeader(),
      success(response) {
        resolve(response);
      },
      error(error) {
        reject(error);
      }
    });
  });
};