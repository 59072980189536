import React, { useContext, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

const AdminRoute = ({ children }) => {
  const { user, isPullingInfo, token, verifyToken, verifyAdmin, logout } =
    useContext(AuthContext);
  const [isVerifying, setIsVerifying] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const verify = async () => {
      if (user.is_login && token) {
        try {
          // Verify token
          await verifyToken(token);

          // Verify admin status
          await verifyAdmin(token);
          setError(null);
        } catch (error) {
          setError("You do not have permission to access this page.");
          logout();
        }
      } else {
        setError("You must be logged in to access this page.");
      }
      setIsVerifying(false);
    };

    verify();
  }, [user, token, verifyToken, verifyAdmin, logout]);

  if (isPullingInfo || isVerifying) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <Navigate to="/404" state={{ from: location }} replace />;
  }

  if (!user.is_login) {
    return <Navigate to="/404" state={{ from: location }} replace />;
  }

  return children;
};

export default AdminRoute;
