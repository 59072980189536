import React, { useState, useEffect, useContext } from "react";
import { Form, Input, Button, Switch, message } from "antd";
import { getAppSettings, updateAppSettings } from "./adminApi";
import { AuthContext } from "../../contexts/AuthContext";

const AppSettings = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { darkMode } = useContext(AuthContext);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    setLoading(true);
    try {
      const settings = await getAppSettings();
      form.setFieldsValue({
        maxFollowedStocks: settings["max.followed.stocks"],
        userDisableRegister: settings["user.disable.register"] === "true",
      });
    } catch (error) {
      message.error("Failed to fetch settings");
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await updateAppSettings({
        "max.followed.stocks": values.maxFollowedStocks.toString(),
        "user.disable.register": values.userDisableRegister.toString(),
      });
      message.success("Settings updated successfully");
    } catch (error) {
      message.error("Failed to update settings");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`app-settings ${darkMode ? "dark-mode" : ""}`}>
      <h2>Application Settings</h2>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="maxFollowedStocks"
          label="Max Followed Stocks"
          rules={[
            {
              required: true,
              message: "Please input the maximum number of followed stocks!",
            },
          ]}
        >
          <Input type="number" min={1} />
        </Form.Item>
        <Form.Item
          name="userDisableRegister"
          label="Disable User Registration"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Update Settings
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AppSettings;
