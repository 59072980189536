import React from "react";
import Base from "../../components/global/base";
import {
  FaQuestionCircle,
  FaChartLine,
  FaLock,
  FaUserShield,
  FaClock,
  FaBalanceScale,
} from "react-icons/fa";

const FAQ = () => {
  return (
    <Base>
      <div className="container mt-5">
        <h1 className="mb-4 text-center">Frequently Asked Questions</h1>
        <p className="lead text-center mb-5">
          Get answers to common questions about Shares Manager
        </p>

        <div className="accordion custom-accordion" id="faqAccordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <FaQuestionCircle className="me-2" /> Is the stock analysis
                guaranteed to be accurate?
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                <strong>
                  No, our service only provides suggestions based on algorithms.
                </strong>{" "}
                While we strive for accuracy, stock market behavior is
                inherently unpredictable. Our analyses and recommendations
                should be used as one of many tools in your decision-making
                process. We strongly advise not to rely solely on our platform
                for investment decisions.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <FaClock className="me-2" /> How often is the stock data
                updated?
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Our platform updates stock data in real-time during market
                hours. However, please note that there might be slight delays
                due to data processing and transmission. For the most up-to-date
                information, always cross-reference with official stock exchange
                data.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <FaBalanceScale className="me-2" /> What does the buy/sell ratio
                indicate?
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                The buy/sell ratio is an indicator of the current market
                sentiment towards a particular stock. A higher buy ratio might
                suggest bullish sentiment, while a higher sell ratio might
                indicate bearish sentiment. However, this is just one of many
                factors to consider and should not be used as the sole basis for
                investment decisions.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                <FaChartLine className="me-2" /> How do I interpret the
                popularity metrics?
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Popularity metrics on our platform indicate how frequently users
                interact with a particular stock. High popularity doesn't
                necessarily correlate with good investment potential. It simply
                means that many users are interested in or watching that stock.
                Always conduct your own research before making investment
                decisions.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFive">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                <FaLock className="me-2" /> Is my personal and financial
                information secure?
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                We take data security very seriously. All personal and financial
                information is encrypted and stored securely. We do not share
                your information with third parties, except as described in our
                Privacy Policy. However, no internet-based system is 100%
                secure, so we encourage users to maintain strong passwords and
                not share their account information.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingSix">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                <FaUserShield className="me-2" /> Do you provide investment
                advice?
              </button>
            </h2>
            <div
              id="collapseSix"
              className="accordion-collapse collapse"
              aria-labelledby="headingSix"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                <strong>
                  No, we do not provide personalized investment advice.
                </strong>{" "}
                Our platform offers tools and data to help you make informed
                decisions, but we do not recommend specific investments. For
                personalized advice, please consult with a qualified financial
                advisor.
              </div>
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default FAQ;
