import React, { useState, useContext } from "react";
import { Form, Input, Button, message } from "antd";
import { SendOutlined, LockOutlined } from "@ant-design/icons";
import API_BASE_URL from "../../services/other/api_settings";
import $ from "jquery";
import { AuthContext } from "../../contexts/AuthContext";
import "../../css/admin/AdminOTPForm.css";

const AdminOTPForm = ({ onOTPVerified }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { darkMode } = useContext(AuthContext);

  const handleRequestOTP = () => {
    setLoading(true);
    $.ajax({
      url: `${API_BASE_URL}/api/admin/request-otp`,
      type: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
      success: function (response) {
        message.success("OTP sent successfully");
      },
      error: function (xhr, status, error) {
        message.error("Failed to send OTP. Please try again.");
        console.error("Error:", error);
      },
      complete: function () {
        setLoading(false);
      },
    });
  };

  const handleSubmit = (values) => {
    setLoading(true);
    $.ajax({
      url: `${API_BASE_URL}/api/admin/validate-otp`,
      type: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
      data: { otp: values.otp },
      success: function (response) {
        if (response.valid) {
          message.success("OTP verified successfully");
          onOTPVerified();
        } else {
          message.error("Invalid OTP. Please try again.");
        }
      },
      error: function (xhr, status, error) {
        message.error("Failed to verify OTP. Please try again.");
        console.error("Error:", error);
      },
      complete: function () {
        setLoading(false);
      },
    });
  };

  return (
    <div className={`admin-otp-form ${darkMode ? "dark-mode" : ""}`}>
      <h2>Admin OTP Verification</h2>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item>
          <Button
            icon={<SendOutlined />}
            onClick={handleRequestOTP}
            loading={loading}
            block
          >
            Request OTP
          </Button>
        </Form.Item>
        <Form.Item
          name="otp"
          rules={[{ required: true, message: "Please enter OTP code" }]}
        >
          <Input prefix={<LockOutlined />} placeholder="Enter OTP" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Verify OTP
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AdminOTPForm;
