import React, { useState, useEffect, useContext } from "react";
import { Link, Route, Routes, useLocation, Navigate } from "react-router-dom";
import { Layout, Menu, Row, Col, Card, Statistic, Spin } from "antd";
import {
  UserOutlined,
  StockOutlined,
  DashboardOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import AdminOTPForm from "./AdminOTPForm";
import UserManagement from "./UserManagement";
import StockManagement from "./StockManagement";
import AppSettings from "../../services/admin/AppSettings";
import { AuthContext } from "../../contexts/AuthContext";
import { getDashboardData } from "../../services/admin/adminApi";
import "../../css/admin/AdminDashboard.css";
import "../../css/admin/AdminStyles.css";

const { Content, Sider } = Layout;

const DashboardOverview = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { darkMode } = useContext(AuthContext);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const data = await getDashboardData();
      setDashboardData(data);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div className={`dashboard-overview ${darkMode ? "dark-mode" : ""}`}>
      <h2>Admin Dashboard</h2>
      <div className="dashboard-content">
        <Row gutter={16}>
          <Col span={12}>
            <Card className={darkMode ? "dark-mode" : ""}>
              <Statistic
                title={
                  <span style={{ color: darkMode ? "#fff" : "inherit" }}>
                    Total Users
                  </span>
                }
                value={dashboardData.totalUsers}
                valueStyle={{ color: darkMode ? "#fff" : "inherit" }}
                prefix={
                  <UserOutlined
                    style={{ color: darkMode ? "#fff" : "inherit" }}
                  />
                }
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card className={darkMode ? "dark-mode" : ""}>
              <Statistic
                title={
                  <span style={{ color: darkMode ? "#fff" : "inherit" }}>
                    Total Stocks
                  </span>
                }
                value={dashboardData.totalStocks}
                valueStyle={{ color: darkMode ? "#fff" : "inherit" }}
                prefix={
                  <StockOutlined
                    style={{ color: darkMode ? "#fff" : "inherit" }}
                  />
                }
              />
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const AdminDashboard = () => {
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [otpExpirationTime, setOtpExpirationTime] = useState(null);
  const { darkMode } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    const checkOTPExpiration = () => {
      if (otpExpirationTime && Date.now() > otpExpirationTime) {
        setIsOTPVerified(false);
      }
    };
    const interval = setInterval(checkOTPExpiration, 1000);
    return () => clearInterval(interval);
  }, [otpExpirationTime]);

  const handleOTPVerified = () => {
    setIsOTPVerified(true);
    setOtpExpirationTime(Date.now() + 5 * 60 * 1000); // 5 minutes from now
  };

  if (!isOTPVerified) {
    return <AdminOTPForm onOTPVerified={handleOTPVerified} />;
  }

  return (
    <Layout className={`admin-dashboard ${darkMode ? "dark-mode" : ""}`}>
      <Sider width={250} className="admin-sider">
        <div className="logo" />
        <Menu
          className="admin-menu"
          selectedKeys={[location.pathname]}
          mode="inline"
        >
          <Menu.Item
            className="admin-menu-content"
            key="/admin"
            icon={<DashboardOutlined />}
          >
            <Link to="/admin">Dashboard Overview</Link>
          </Menu.Item>
          <Menu.Item
            className="admin-menu-content"
            key="/admin/users"
            icon={<UserOutlined />}
          >
            <Link to="/admin/users">Manage Users</Link>
          </Menu.Item>
          <Menu.Item
            className="admin-menu-content"
            key="/admin/stocks"
            icon={<StockOutlined />}
          >
            <Link to="/admin/stocks">Manage Stocks</Link>
          </Menu.Item>
          <Menu.Item
            className="admin-menu-content"
            key="/admin/settings"
            icon={<SettingOutlined />}
          >
            <Link to="/admin/settings">App Settings</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Content className="site-content">
          <Routes>
            <Route index element={<DashboardOverview />} />
            <Route path="users" element={<UserManagement />} />
            <Route path="stocks" element={<StockManagement />} />
            <Route path="settings" element={<AppSettings />} />
            <Route path="*" element={<Navigate to="/admin" replace />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminDashboard;
