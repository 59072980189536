import React, { useContext, useState, useCallback } from "react";
import { message, Button, Space, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import JSEncrypt from "jsencrypt";
import EnhancedTable from "../../components/admin/EnhancedTable";
import {
  getUserList,
  updateUser,
  deleteUser,
  addUser,
} from "../../services/admin/adminApi";
import AddItemModal from "../../components/admin/AddItemModal";
import { AuthContext } from "../../contexts/AuthContext";
import { PUBLIC_KEY } from "../../services/other/crypto_settings_loader";
const { confirm } = Modal;

const UserManagement = () => {
  const { darkMode } = useContext(AuthContext);
  const [tableKey, setTableKey] = useState(0);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [addUserError, setAddUserError] = useState(null);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: true,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      sorter: true,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Button danger onClick={() => showDeleteConfirm(record.id)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const fetchData = useCallback(
    async ({ pagination, filters, sorter, searchText }) => {
      try {
        const response = await getUserList({
          page: pagination.current,
          pageSize: pagination.pageSize,
          searchText,
          sortField: sorter.field,
          sortOrder: sorter.order,
        });

        if (Array.isArray(response)) {
          return {
            data: response,
            total: response.length,
          };
        } else if (response && Array.isArray(response.data)) {
          return {
            data: response.data,
            total: response.total || response.data.length,
          };
        } else {
          message.error("Invalid data format received from server");
          return { data: [], total: 0 };
        }
      } catch (error) {
        message.error("Failed to fetch users");
        return { data: [], total: 0 };
      }
    },
    []
  );

  const showDeleteConfirm = useCallback(
    (userId) => {
      confirm({
        title: "Are you sure you want to delete this user?",
        icon: <ExclamationCircleOutlined />,
        content: "This action cannot be undone.",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          handleDeleteUser(userId);
        },
        className: darkMode ? "dark-mode" : "",
        maskClosable: true,
        okButtonProps: { className: darkMode ? "dark-mode" : "" },
        cancelButtonProps: { className: darkMode ? "dark-mode" : "" },
      });
    },
    [darkMode]
  );

  const handleDeleteUser = async (userId) => {
    try {
      await deleteUser(userId);
      message.success("User deleted successfully");
      setTableKey((prevKey) => prevKey + 1); // Force table refresh
    } catch (error) {
      message.error("Failed to delete user");
    }
  };

  const handleAddUser = async (values) => {
    try {
      // Encrypt the password
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(PUBLIC_KEY);
      const encryptedPassword = encrypt.encrypt(values.password);

      // Replace the plain text password with the encrypted password
      const userDataWithEncryptedPassword = {
        ...values,
        password: encryptedPassword,
      };

      await addUser(userDataWithEncryptedPassword);
      message.success("User added successfully");
      setIsAddModalVisible(false);
      setAddUserError(null);
      setTableKey((prevKey) => prevKey + 1); // Force table refresh
    } catch (error) {
      setAddUserError(error.message);
    }
  };

  const addUserFields = [
    { name: "username", label: "Username", type: "text" },
    { name: "email", label: "Email", type: "email" },
    { name: "password", label: "Password", type: "password" },
    {
      name: "role",
      label: "Role",
      type: "select",
      options: [
        { value: "USER", label: "User" },
        { value: "ADMIN", label: "Admin" },
      ],
    },
  ];

  const addUserButton = (
    <Button onClick={() => setIsAddModalVisible(true)} type="primary">
      Add User
    </Button>
  );

  return (
    <div className="user-management">
      <h2>User Management</h2>
      <EnhancedTable
        key={tableKey}
        columns={columns}
        fetchData={fetchData}
        updateItem={updateUser}
        deleteItem={handleDeleteUser}
        searchPlaceholder="Search Users"
        rightAlignedButton={addUserButton}
      />
      <AddItemModal
        visible={isAddModalVisible}
        onCancel={() => {
          setIsAddModalVisible(false);
          setAddUserError(null);
        }}
        onAdd={handleAddUser}
        fields={addUserFields}
        error={addUserError}
      />
    </div>
  );
};

export default UserManagement;
