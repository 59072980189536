import React, { useContext, useEffect } from "react";
import { Modal, Form, Input, InputNumber, Button, Alert, Select } from "antd";
import { AuthContext } from "../../contexts/AuthContext";

const { Option } = Select;

const AddItemModal = ({ visible, onCancel, onAdd, fields, error }) => {
  const [form] = Form.useForm();
  const { darkMode } = useContext(AuthContext);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        onAdd(values);
        form.resetFields();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const renderFormItem = (field) => {
    switch (field.type) {
      case "number":
        return (
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            max={100}
            formatter={(value) => {
              // Limit to 3 digits and remove non-numeric characters
              const filtered = `${value}`.replace(/[^0-9]/g, "").slice(0, 3);
              return filtered;
            }}
            parser={(value) => {
              // Parse the value and ensure it's between 0 and 100
              const parsed = parseInt(value || "0", 10);
              return isNaN(parsed) ? 0 : Math.min(100, Math.max(0, parsed));
            }}
          />
        );
      case "select":
        return (
          <Select>
            {field.options.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        );
      case "password":
        return <Input.Password />;
      default:
        return <Input />;
    }
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, form]);

  return (
    <Modal
      open={visible}
      title="Add New Item"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Add
        </Button>,
      ]}
      className={`add-item-modal ${darkMode ? "dark-mode" : ""}`}
    >
      {error && (
        <Alert message={error} type="error" style={{ marginBottom: 16 }} />
      )}
      <Form form={form} layout="vertical">
        {fields.map((field) => (
          <Form.Item
            key={field.name}
            name={field.name}
            label={field.label}
            rules={[
              { required: true, message: `Please input the ${field.label}!` },
            ]}
          >
            {renderFormItem(field)}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};

export default AddItemModal;
