import React, { useState, useEffect, useContext } from "react";
import { Table, Input, Button, Select, Space, message, Row, Col } from "antd";
import { SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import { AuthContext } from "../../contexts/AuthContext"; // Adjust the import path as needed

const { Option } = Select;
const EnhancedTable = ({
  columns,
  fetchData,
  updateItem,
  deleteItem,
  searchPlaceholder,
  rightAlignedButton,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [filters, setFilters] = useState({});
  const [sorter, setSorter] = useState({});
  const [searchText, setSearchText] = useState("");
  const { darkMode } = useContext(AuthContext);

  useEffect(() => {
    fetchTableData();
  }, [
    JSON.stringify(pagination),
    JSON.stringify(filters),
    JSON.stringify(sorter),
  ]);

  const fetchTableData = async () => {
    setLoading(true);
    try {
      const result = await fetchData({
        pagination,
        filters,
        sorter,
        searchText,
      });

      if (result && result.data && Array.isArray(result.data)) {
        setData(result.data);
        setPagination((prev) => ({
          ...prev,
          total: result.total || result.data.length,
        }));
      } else {
        console.error("Invalid data format received:", result);
        message.error("Error loading data. Please try again.");
        setData([]);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      message.error("Error loading data. Please try again.");
      setData([]);
    }
    setLoading(false);
  };

  const handleTableChange = (newPagination, newFilters, newSorter) => {
    // Check if sorting has changed
    if (JSON.stringify(newSorter) !== JSON.stringify(sorter)) {
      // Reset to page 1 if sorting has changed
      newPagination.current = 1;
    }
    setPagination(newPagination);
    setFilters(newFilters);
    setSorter(newSorter);
  };

  const handleSearch = () => {
    setPagination((prev) => ({ ...prev, current: 1 }));
    fetchTableData();
  };

  const handleReset = () => {
    setSearchText("");
    setFilters({});
    setSorter({});
    setPagination((prev) => ({ ...prev, current: 1 }));
    fetchTableData();
  };

  // // Not yet used
  // const enhancedColumns = columns.map((col) => {
  //   if (!col.dataIndex) {
  //     return col;
  //   }
  //   return {
  //     ...col,
  //     filteredValue: filters[col.dataIndex] || null,
  //     sorter: true,
  //   };
  // });

  return (
    <div className={`enhanced-table ${darkMode ? "dark-mode" : ""}`}>
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Col>
          <Space>
            <Input
              placeholder={searchPlaceholder || "Search"}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onPressEnter={handleSearch}
              style={{ width: 200 }}
              className={darkMode ? "dark-mode" : ""}
            />
            <Button
              type="primary"
              onClick={handleSearch}
              icon={<SearchOutlined />}
            >
              Search
            </Button>
            <Button onClick={handleReset} icon={<ReloadOutlined />}>
              Reset
            </Button>
            <Select
              style={{ width: 120 }}
              value={pagination.pageSize}
              onChange={(value) =>
                setPagination((prev) => ({ ...prev, pageSize: value }))
              }
              className={darkMode ? "dark-mode" : ""}
            >
              <Option value={10}>10 / page</Option>
              <Option value={20}>20 / page</Option>
              <Option value={50}>50 / page</Option>
            </Select>
          </Space>
        </Col>
        <Col>{rightAlignedButton}</Col>
      </Row>
      <Table
        columns={columns}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        rowKey="id"
        className={darkMode ? "dark-mode" : ""}
      />
    </div>
  );
};

export default EnhancedTable;
