import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Base from "../../components/global/base";
import Combobox from "../../components/algorithms/NewCombobox";
import StockAnalysisDisplay from "../../components/algorithms/StockAnalysisDisplay";
import SignalDetailedDisplay from "../../components/algorithms/SignalDetailedDisplay";
import WatchListAccordion from "../../components/algorithms/WatchListAccordion";
import $ from "jquery";
import { FaSpinner } from "react-icons/fa";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import API_BASE_URL from "../../services/other/api_settings";
import "../../css/algorithms/algorithms.css";

const NewAlgorithms = () => {
  const [selectedStock, setSelectedStock] = useState(null);
  const [selectedTime, setSelectedTime] = useState("1m");
  // const [answer, setAnswer] = useState("");
  const [answer] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [stockData, setStockData] = useState([]);
  const { user } = useContext(AuthContext);
  const intervalRef = useRef(null);
  const [countdown, setCountdown] = useState(0);
  const countdownIntervalRef = useRef(null);
  const isUpdatingRef = useRef(false);

  const [signalCounts, setSignalCounts] = useState({
    buy: 0,
    sell: 0,
    neutral: 0,
    timestamp: "",
  });

  const [signals, setSignals] = useState([]);

  const [watchLists, setWatchLists] = useState([]);
  const [activeWatchList, setActiveWatchList] = useState(null);

  const fetchStocks = async (query) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${API_BASE_URL}/api/stock_symbols`,
        type: "get",
        data: { query },
        success(resp) {
          resolve(resp.slice(0, 10));
        },
        error(resp) {
          console.error("Error fetching stocks:", resp);
          reject(resp);
        },
      });
    });
  };

  const runAlgorithm = useCallback(() => {
    if (!selectedStock || isUpdatingRef.current) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      const request = $.ajax({
        url: `${API_BASE_URL}/api/signal-analysis`,
        type: "get",
        data: {
          stockName: selectedStock.symbol,
          selectedTime: selectedTime,
        },
        success(resp) {
          const updatedCounts = {
            buy: resp.counts.buy ?? 0,
            sell: resp.counts.sell ?? 0,
            neutral: resp.counts.neutral ?? 0,
            timestamp: resp.counts.timestamp ?? "",
          };
          setSignalCounts(updatedCounts);
          setSignals(resp.detailedSignals ?? []);
          resolve();
        },
        error(xhr, status, error) {
          reject(error);
        },
      });
      return () => request.abort();
    });
  }, [selectedStock, selectedTime]);

  const fetchStockData = useCallback(
    (retryCount = 0) => {
      if (!selectedStock || isUpdatingRef.current) return Promise.resolve();
      return new Promise((resolve, reject) => {
        const request = $.ajax({
          url: `${API_BASE_URL}/api/stock-data`,
          type: "get",
          data: {
            stockId: selectedStock.id,
            interval: selectedTime,
          },
          success(resp) {
            if (resp && resp.length > 0) {
              const processedData = resp.map((item) => ({
                ...item,
                timekey: new Date(item.timekey).getTime(),
                close: parseFloat(item.close),
              }));
              setStockData(processedData);
              resolve();
            } else {
              // Handle empty response (potentially a 204 status)
              if (retryCount < 3) {
                console.log(
                  `Retrying fetchStockData (attempt ${retryCount + 1})`
                );
                setTimeout(() => {
                  fetchStockData(retryCount + 1)
                    .then(resolve)
                    .catch(reject);
                }, 3000); // Wait 3 seconds before retrying
              } else {
                console.error(
                  "Max retries reached. Unable to fetch stock data."
                );
                reject(new Error("Max retries reached"));
              }
            }
          },
          error(xhr, status, error) {
            if (xhr.status === 204) {
              if (retryCount < 3) {
                console.log(
                  `Retrying fetchStockData (attempt ${retryCount + 1})`
                );
                setTimeout(() => {
                  fetchStockData(retryCount + 1)
                    .then(resolve)
                    .catch(reject);
                }, 1000); // Wait 1 second before retrying
              } else {
                console.error(
                  "Max retries reached. Unable to fetch stock data."
                );
                reject(new Error("Max retries reached"));
              }
            } else {
              console.error("Error fetching stock data:", error);
              reject(error);
            }
          },
        });
        return () => request.abort();
      });
    },
    [selectedStock, selectedTime]
  );

  const getStockLabel = (stock) => `${stock.symbol} - ${stock.companyName}`;

  const viewStock = useCallback((stockId) => {
    $.ajax({
      url: `${API_BASE_URL}/api/user-stocks/view`,
      type: "post",
      data: { stockId },
      error(resp) {
        console.error("Error recording stock view:", resp);
      },
    });
  }, []);

  const updateData = useCallback(() => {
    if (selectedStock) {
      setIsLoading(true);
      Promise.all([runAlgorithm(), fetchStockData()])
        .then(() => {
          setIsLoading(false);
          setCountdown(getIntervalTime(selectedTime) / 1000);
        })
        .catch((error) => {
          console.error("Error updating data:", error);
          setIsLoading(false);
        });
    }
  }, [selectedStock, selectedTime, runAlgorithm, fetchStockData]);

  useEffect(() => {
    if (selectedStock) {
      // Clear existing intervals
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (countdownIntervalRef.current) {
        clearInterval(countdownIntervalRef.current);
      }

      const intervalTime = getIntervalTime(selectedTime);

      // Set initial countdown
      setCountdown(intervalTime / 1000);

      // Create countdown timer
      countdownIntervalRef.current = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            // For long intervals, don't auto-update
            if (intervalTime > 3600000) {
              return 0;
            }
            updateData();
            return intervalTime / 1000;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      // For intervals shorter than or equal to 1 hour, set up the update interval
      if (intervalTime <= 3600000) {
        intervalRef.current = setInterval(updateData, intervalTime);
      }

      // Cleanup function
      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
        if (countdownIntervalRef.current) {
          clearInterval(countdownIntervalRef.current);
        }
      };
    }
  }, [selectedStock, selectedTime, updateData]);

  const getIntervalTime = (interval) => {
    const timeMap = {
      "1m": 60000,
      "3m": 180000,
      "5m": 300000,
      "10m": 600000,
      "15m": 900000,
      "30m": 1800000,
      "1h": 3600000,
      "2h": 7200000,
      "3h": 10800000,
      "4h": 14400000,
      "1d": 86400000,
      "5d": 432000000,
      "1wk": 604800000,
      "1mo": 2592000000,
      "3mo": 7776000000,
    };
    return timeMap[interval] || 60000; // default to 1 minute if interval not found
  };

  const formatCountdown = (seconds) => {
    if (seconds === 0) return "N/A (Manual update required)";
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (days > 0) {
      return `${days}d ${hours}h ${minutes}m ${remainingSeconds}s`;
    } else if (hours > 0) {
      return `${hours}h ${minutes}m ${remainingSeconds}s`;
    } else {
      return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
        .toString()
        .padStart(2, "0")}`;
    }
  };

  const formatXAxis = (tickItem) => {
    return new Date(tickItem).toLocaleDateString();
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p>{`Time: ${new Date(label).toLocaleString()}`}</p>
          <p>{`Close: $${payload[0].value.toFixed(2)}`}</p>
        </div>
      );
    }
    return null;
  };

  const fetchWatchLists = useCallback(() => {
    fetch(`${API_BASE_URL}/api/watchlists/${user.id}`)
      .then((response) => response.json())
      .then((data) => {
        const watchListsArray = Array.isArray(data) ? data : [];
        setWatchLists(watchListsArray);
        if (watchListsArray.length > 0 && !activeWatchList) {
          setActiveWatchList(watchListsArray[0]);
        }
      })
      .catch((error) => {
        console.error("Error fetching watch lists:", error);
        setWatchLists([]);
      });
  }, [user.id, activeWatchList]);

  const deleteWatchList = (listId) => {
    fetch(`${API_BASE_URL}/api/watchlists/${listId}`, { method: "DELETE" })
      .then(() => {
        setWatchLists((prevWatchLists) => {
          const updatedWatchLists = prevWatchLists.filter(
            (list) => list.id !== listId
          );

          if (activeWatchList && activeWatchList.id === listId) {
            // If the deleted list was the active one, set the new active list
            setActiveWatchList(updatedWatchLists[0] || null);
          }

          return updatedWatchLists;
        });
      })
      .catch((error) => console.error("Error deleting watch list:", error));
  };

  const addToWatchList = () => {
    if (!activeWatchList || !selectedStock) return;

    fetch(`${API_BASE_URL}/api/watchlists/${activeWatchList.id}/stocks`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ stockId: selectedStock.id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((updatedWatchList) => {
        setActiveWatchList(updatedWatchList);
        setWatchLists(
          watchLists.map((list) =>
            list.id === activeWatchList.id ? updatedWatchList : list
          )
        );
      })
      .catch((error) => {
        console.error("Error adding stock to watch list:", error);
        // You can add user-friendly error handling here, such as displaying an error message
      });
  };

  const removeFromWatchList = (watchListId, stockId) => {
    fetch(`${API_BASE_URL}/api/watchlists/${watchListId}/stocks/${stockId}`, {
      method: "DELETE",
    })
      .then(() => {
        setWatchLists(
          watchLists.map((list) =>
            list.id === watchListId
              ? {
                  ...list,
                  stocks: list.stocks.filter((stock) => stock.id !== stockId),
                }
              : list
          )
        );
        if (activeWatchList && activeWatchList.id === watchListId) {
          setActiveWatchList({
            ...activeWatchList,
            stocks: activeWatchList.stocks.filter(
              (stock) => stock.id !== stockId
            ),
          });
        }
      })
      .catch((error) =>
        console.error("Error removing stock from watch list:", error)
      );
  };

  const handleStockSelect = useCallback((stock) => {
    setSelectedStock((prevStock) => {
      if (stock === null) {
        // If stock is null, clear the selection
        return null;
      }
      if (prevStock && stock && prevStock.id === stock.id) {
        return prevStock; // Don't update if it's the same stock
      }
      return stock;
    });
  }, []);

  const handleWatchListStockSelect = useCallback((stock) => {
    setSelectedStock((prevStock) => {
      if (stock === null) {
        // If stock is null, clear the selection
        return null;
      }
      if (prevStock && stock && prevStock.id === stock.id) {
        return prevStock; // Don't update if it's the same stock
      }
      return stock;
    });
  }, []);

  useEffect(() => {
    fetchWatchLists();
  }, [fetchWatchLists]);

  useEffect(() => {
    if (selectedStock) {
      updateData();
      viewStock(selectedStock.id);
    } else {
      // Clear data or set default state when no stock is selected
      setStockData([]);
      setSignalCounts({
        buy: 0,
        sell: 0,
        neutral: 0,
        timestamp: "",
      });
      setSignals([]);
    }
  }, [selectedStock, updateData, viewStock]);

  return (
    <Base>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 col-lg-3 mb-4">
            <WatchListAccordion
              watchLists={watchLists}
              setWatchLists={setWatchLists}
              activeWatchList={activeWatchList}
              setActiveWatchList={setActiveWatchList}
              onDeleteWatchList={deleteWatchList}
              onRemoveStock={removeFromWatchList}
              user={user}
              onSelectStock={handleWatchListStockSelect}
            />
          </div>
          <div className="col-md-8 col-lg-9">
            <div className="mb-3">
              <label htmlFor="selectedTime" className="form-label">
                Find your stock
              </label>
              <Combobox
                id="stockCombobox"
                value={selectedStock}
                onChange={handleStockSelect}
                fetchOptions={fetchStocks}
                getOptionLabel={getStockLabel}
                placeholder="Search for a stock"
                maxOptions={10}
              />
              {activeWatchList && selectedStock && (
                <div className="d-flex justify-content-end mt-2">
                  <button
                    className="btn btn-primary"
                    onClick={addToWatchList}
                    disabled={!activeWatchList}
                  >
                    {activeWatchList
                      ? `Add to Watch List: ${activeWatchList.name}`
                      : "Add to Watch List"}
                  </button>
                </div>
              )}
            </div>
            <form onSubmit={(e) => e.preventDefault()} className="mt-4">
              <div className="mb-3">
                <label htmlFor="selectedTime" className="form-label">
                  Select interval
                </label>
                <select
                  value={selectedTime}
                  onChange={(e) => setSelectedTime(e.target.value)}
                  id="selectedTime"
                  className="form-select"
                >
                  <option value="1m">1 minute</option>
                  <option value="3m">3 minutes</option>
                  <option value="5m">5 minutes</option>
                  <option value="10m">10 minutes</option>
                  <option value="15m">15 minutes</option>
                  <option value="30m">30 minutes</option>
                  <option value="1h">1 hour</option>
                  <option value="2h">2 hours</option>
                  <option value="3h">3 hours</option>
                  <option value="4h">4 hours</option>
                  <option value="1d">1 day</option>
                  <option value="5d">5 days</option>
                  <option value="1wk">1 week</option>
                  <option value="1mo">1 month</option>
                  <option value="3mo">3 months</option>
                </select>
              </div>
            </form>
            {selectedStock && (
              <div className="mb-3 d-flex justify-content-between align-items-center">
                <div>
                  <strong>Next update in: </strong>
                  <span className="countdown-timer">
                    {formatCountdown(countdown)}
                  </span>
                </div>
                <button
                  className="btn btn-primary"
                  onClick={updateData}
                  disabled={isLoading}
                >
                  {getIntervalTime(selectedTime) > 3600000
                    ? "Manual Update Data"
                    : "Force Run Algorithms"}
                </button>
              </div>
            )}
            <div className="mt-4" style={{ whiteSpace: "pre-line" }}>
              {answer}
            </div>
            {selectedStock && (
              <StockAnalysisDisplay signalCounts={signalCounts} />
            )}
            {selectedStock && (
              <div className="chart-container">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={stockData}>
                    <XAxis dataKey="timekey" tickFormatter={formatXAxis} />
                    <YAxis domain={["auto", "auto"]} />
                    <Tooltip content={<CustomTooltip />} />
                    <Line
                      type="monotone"
                      dataKey="close"
                      stroke="#8884d8"
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )}
            {selectedStock && signals.length > 0 && (
              <SignalDetailedDisplay signals={signals} />
            )}
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="loading-overlay">
          <FaSpinner className="spinner" />
        </div>
      )}
    </Base>
  );
};

export default NewAlgorithms;
