import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import API_BASE_URL from "../services/other/api_settings";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({
    id: "",
    username: "",
    photo: "",
    is_login: false,
    role: "",
  });
  const [token, setToken] = useState("");
  const [isPullingInfo, setIsPullingInfo] = useState(true);
  const navigate = useNavigate();

  const getInfo = useCallback((token) => {
    setIsPullingInfo(true);
    $.ajax({
      url: `${API_BASE_URL}/user/account/info/`,
      type: "get",
      headers: {
        Authorization: "Bearer " + token,
      },
      success(resp) {
        if (resp.error_message === "success") {
          setUser({
            ...resp,
            is_login: true,
          });
        }
        setIsPullingInfo(false);
      },
      error() {
        setIsPullingInfo(false);
      }
    });
  }, []);

  const verifyToken = useCallback((token) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${API_BASE_URL}/user/account/verify`,
        type: "post",
        headers: {
          Authorization: "Bearer " + token,
        },
        success(resp) {
          if (resp.isValid) {
            resolve(true);
          } else {
            reject(new Error("Invalid token"));
          }
        },
        error(error) {
          reject(error);
        }
      });
    });
  }, []);

  const verifyAdmin = useCallback((token) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${API_BASE_URL}/user/account/verify-admin`,  // Updated endpoint
        type: "post",
        headers: {
          Authorization: "Bearer " + token,
        },
        success(resp) {
          if (resp.isAdmin) {
            resolve(true);
          } else {
            reject(new Error("User is not an admin"));
          }
        },
        error(error) {
          reject(error);
        }
      });
    });
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem("jwt_token");
    if (storedToken) {
      setToken(storedToken);
      verifyToken(storedToken)
        .then(() => getInfo(storedToken))
        .catch(() => {
          localStorage.removeItem("jwt_token");
          setUser({
            id: "",
            username: "",
            photo: "",
            is_login: false,
            role: "",
          });
          setToken("");
        })
        .finally(() => setIsPullingInfo(false));
    } else {
      setIsPullingInfo(false);
    }
  }, [getInfo, verifyToken]);

  const login = useCallback((username, encryptedPassword) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${API_BASE_URL}/user/account/token/`,
        type: "post",
        data: { username, password: encryptedPassword },
        success(resp) {
          if(resp.error_message === "success") {
            localStorage.setItem("jwt_token", resp.token);
            setToken(resp.token);
            getInfo(resp.token);
            resolve(resp);
          } else {
            reject(resp);
          }
        },
        error(resp) {
          reject(resp);
        }
      });
    });
  }, [getInfo]);

  const logout = useCallback(() => {
    localStorage.removeItem("jwt_token");
    setUser({
      id: "",
      username: "",
      photo: "",
      is_login: false,
      role: "",
    });
    setToken("");
    navigate('/login');
  }, [navigate]);

  const isAdmin = useCallback(() => {
    return user.role === "ADMIN";
  }, [user.role]);

  return (
    <AuthContext.Provider value={{
      user,
      token,
      isPullingInfo,
      login,
      getInfo,
      logout,
      setIsPullingInfo,
      isAdmin,
      verifyToken,
      verifyAdmin,
    }}>
      {children}
    </AuthContext.Provider>
  );
};