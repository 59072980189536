import React, { useContext, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

const ProtectedRoute = ({ children }) => {
  const { user, isPullingInfo, token, verifyToken, logout } =
    useContext(AuthContext);
  const [isVerifying, setIsVerifying] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const verify = async () => {
      if (user.is_login && token) {
        try {
          await verifyToken(token);
        } catch (error) {
          console.error("Token verification failed:", error);
          logout();
        }
      }
      setIsVerifying(false);
    };

    verify();
  }, [user, token, verifyToken, logout]);

  if (isPullingInfo || isVerifying) {
    return <div>Loading...</div>;
  }

  if (!user.is_login) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
