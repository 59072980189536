import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Base from "../../components/global/base";
import Combobox from "../../components/algorithms/Combobox";
import StockAnalysisDisplay from "../../components/algorithms/StockAnalysisDisplay";
import SignalDetailedDisplay from "../../components/algorithms/SignalDetailedDisplay";
import $ from "jquery";
import { FaHeart, FaSpinner, FaChevronDown, FaChevronUp } from "react-icons/fa";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import API_BASE_URL from "../../services/other/api_settings";
import "../../css/algorithms/algorithms.css";

const Algorithms = () => {
  const [selectedStock, setSelectedStock] = useState(null);
  const [selectedTime, setSelectedTime] = useState("1m");
  const [answer, setAnswer] = useState("");
  const [followedStocks, setFollowedStocks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFollowedStocksOpen, setIsFollowedStocksOpen] = useState(true);
  const [stockData, setStockData] = useState([]);
  const { user } = useContext(AuthContext);
  const intervalRef = useRef(null);
  const [countdown, setCountdown] = useState(0);
  const countdownIntervalRef = useRef(null);
  const isUpdatingRef = useRef(false);

  const [signalCounts, setSignalCounts] = useState({
    buy: 0,
    sell: 0,
    neutral: 0,
    timestamp: "",
  });

  const [signals, setSignals] = useState([]);

  const fetchStocks = async (query) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${API_BASE_URL}/api/stock_symbols`,
        type: "get",
        data: { query },
        success(resp) {
          resolve(resp.slice(0, 10));
        },
        error(resp) {
          console.error("Error fetching stocks:", resp);
          reject(resp);
        },
      });
    });
  };

  const runAlgorithm = useCallback(() => {
    if (!selectedStock || isUpdatingRef.current) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      const request = $.ajax({
        url: `${API_BASE_URL}/api/signal-analysis`,
        type: "get",
        data: {
          stockName: selectedStock.symbol,
          selectedTime: selectedTime,
        },
        success(resp) {
          const updatedCounts = {
            buy: resp.counts.buy ?? 0,
            sell: resp.counts.sell ?? 0,
            neutral: resp.counts.neutral ?? 0,
            timestamp: resp.counts.timestamp ?? "",
          };
          setSignalCounts(updatedCounts);
          setSignals(resp.detailedSignals ?? []);
          resolve();
        },
        error(xhr, status, error) {
          reject(error);
        },
      });
      return () => request.abort();
    });
  }, [selectedStock, selectedTime]);

  const fetchStockData = useCallback(() => {
    if (!selectedStock || isUpdatingRef.current) return Promise.resolve();
    return new Promise((resolve, reject) => {
      const request = $.ajax({
        url: `${API_BASE_URL}/api/stock-data`,
        type: "get",
        data: {
          stockId: selectedStock.id,
          interval: selectedTime,
        },
        success(resp) {
          const processedData = resp.map((item) => ({
            ...item,
            timekey: new Date(item.timekey).getTime(),
            close: parseFloat(item.close),
          }));
          setStockData(processedData);
          resolve();
        },
        error(resp) {
          console.error("Error fetching stock data:", resp);
          reject(resp);
        },
      });
      return () => request.abort();
    });
  }, [selectedStock, selectedTime]);

  const getStockLabel = (stock) => `${stock.symbol} - ${stock.companyName}`;

  const toggleFollowStock = useCallback(
    (stock) => {
      const isCurrentlyFollowed = followedStocks.some((s) => s.id === stock.id);
      const action = isCurrentlyFollowed ? "unfollow" : "follow";

      $.ajax({
        url: `${API_BASE_URL}/api/user-stocks/${action}`,
        type: isCurrentlyFollowed ? "delete" : "post",
        data: { userId: user.id, stockId: stock.id },
        success() {
          setFollowedStocks((prev) =>
            isCurrentlyFollowed
              ? prev.filter((s) => s.id !== stock.id)
              : [...prev, stock]
          );
        },
        error(resp) {
          console.error(`Error ${action}ing stock:`, resp);
        },
      });
    },
    [user.id, followedStocks]
  );

  const fetchFollowedStocks = useCallback(() => {
    if (!user.id) return;

    $.ajax({
      url: `${API_BASE_URL}/api/user-stocks/followed`,
      type: "get",
      data: { userId: user.id },
      success(resp) {
        setFollowedStocks(resp);
      },
      error(resp) {
        console.error("Error fetching followed stocks:", resp);
      },
    });
  }, [user.id]);

  const viewStock = useCallback((stockId) => {
    $.ajax({
      url: `${API_BASE_URL}/api/user-stocks/view`,
      type: "post",
      data: { stockId },
      error(resp) {
        console.error("Error recording stock view:", resp);
      },
    });
  }, []);

  const toggleFollowedStocks = () => {
    setIsFollowedStocksOpen(!isFollowedStocksOpen);
  };

  // useEffect(() => {
  //   fetchFollowedStocks();
  // }, [fetchFollowedStocks]);

  // useEffect(() => {
  //   if (selectedStock) {
  //     fetchStockData();
  //     runAlgorithm();
  //     viewStock(selectedStock.id);
  //   }
  // }, [selectedStock, selectedTime, fetchStockData, runAlgorithm, viewStock]);

  const isStockFollowed = useCallback(
    (stock) => {
      return followedStocks.some((s) => s.id === stock.id);
    },
    [followedStocks]
  );

  const formatXAxis = (tickItem) => {
    return new Date(tickItem).toLocaleDateString();
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p>{`Time: ${new Date(label).toLocaleString()}`}</p>
          <p>{`Close: $${payload[0].value.toFixed(2)}`}</p>
        </div>
      );
    }
    return null;
  };

  const updateData = useCallback(() => {
    if (selectedStock) {
      setIsLoading(true);
      Promise.all([runAlgorithm(), fetchStockData()])
        .then(() => {
          setIsLoading(false);
          setCountdown(getIntervalTime(selectedTime) / 1000);
        })
        .catch((error) => {
          console.error("Error updating data:", error);
          setIsLoading(false);
        });
    }
  }, [selectedStock, selectedTime, runAlgorithm, fetchStockData]);

  useEffect(() => {
    fetchFollowedStocks();
    if (selectedStock) {
      updateData();
      viewStock(selectedStock.id);

      // Clear existing intervals
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (countdownIntervalRef.current) {
        clearInterval(countdownIntervalRef.current);
      }

      const intervalTime = getIntervalTime(selectedTime);

      // Set initial countdown
      setCountdown(intervalTime / 1000);

      // Create countdown timer
      countdownIntervalRef.current = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            // For long intervals, don't auto-update
            if (intervalTime > 3600000) {
              return 0;
            }
            updateData();
            return intervalTime / 1000;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      // For intervals shorter than or equal to 1 hour, set up the update interval
      if (intervalTime <= 3600000) {
        intervalRef.current = setInterval(updateData, intervalTime);
      }

      // Cleanup function
      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
        if (countdownIntervalRef.current) {
          clearInterval(countdownIntervalRef.current);
        }
      };
    }
  }, [selectedStock, selectedTime, updateData, viewStock]);

  const getIntervalTime = (interval) => {
    const timeMap = {
      "1m": 60000,
      "3m": 180000,
      "5m": 300000,
      "10m": 600000,
      "15m": 900000,
      "30m": 1800000,
      "1h": 3600000,
      "2h": 7200000,
      "3h": 10800000,
      "4h": 14400000,
      "1d": 86400000,
      "5d": 432000000,
      "1wk": 604800000,
      "1mo": 2592000000,
      "3mo": 7776000000,
    };
    return timeMap[interval] || 60000; // default to 1 minute if interval not found
  };

  const formatCountdown = (seconds) => {
    if (seconds === 0) return "N/A (Manual update required)";
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (days > 0) {
      return `${days}d ${hours}h ${minutes}m ${remainingSeconds}s`;
    } else if (hours > 0) {
      return `${hours}h ${minutes}m ${remainingSeconds}s`;
    } else {
      return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
        .toString()
        .padStart(2, "0")}`;
    }
  };

  return (
    <Base>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 col-lg-3 mb-4">
            <button className="toggle-button" onClick={toggleFollowedStocks}>
              <span>My Followed Stocks</span>
              {isFollowedStocksOpen ? <FaChevronUp /> : <FaChevronDown />}
            </button>
            <div
              className={`followed-stocks-container ${
                isFollowedStocksOpen ? "" : "closed"
              }`}
            >
              <div className="list-group mt-2">
                {followedStocks.map((stock) => (
                  <button
                    key={stock.id}
                    className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                    onClick={() => setSelectedStock(stock)}
                  >
                    <span>{stock.symbol}</span>
                    <FaHeart
                      className="heart-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleFollowStock(stock);
                      }}
                    />
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="col-md-8 col-lg-9">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="mb-3">
                <label htmlFor="stockCombobox" className="form-label">
                  Stock Symbol
                </label>
                <Combobox
                  id="stockCombobox"
                  value={selectedStock}
                  onChange={(newValue) => {
                    setSelectedStock(newValue);
                    if (!newValue) {
                      setAnswer("");
                    }
                  }}
                  fetchOptions={fetchStocks}
                  getOptionLabel={getStockLabel}
                  placeholder="Search for a stock"
                  maxOptions={10}
                  onFollowToggle={toggleFollowStock}
                  isOptionFollowed={isStockFollowed}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="selectedTime" className="form-label">
                  Select interval
                </label>
                <select
                  value={selectedTime}
                  onChange={(e) => setSelectedTime(e.target.value)}
                  id="selectedTime"
                  className="form-select"
                >
                  <option value="1m">1 minute</option>
                  <option value="3m">3 minutes</option>
                  <option value="5m">5 minutes</option>
                  <option value="10m">10 minutes</option>
                  <option value="15m">15 minutes</option>
                  <option value="30m">30 minutes</option>
                  <option value="1h">1 hour</option>
                  <option value="2h">2 hours</option>
                  <option value="3h">3 hours</option>
                  <option value="4h">4 hours</option>
                  <option value="1d">1 day</option>
                  <option value="5d">5 days</option>
                  <option value="1wk">1 week</option>
                  <option value="1mo">1 month</option>
                  <option value="3mo">3 months</option>
                </select>
              </div>
            </form>
            {selectedStock && (
              <div className="mb-3 d-flex justify-content-between align-items-center">
                <div>
                  <strong>Next update in: </strong>
                  <span className="countdown-timer">
                    {formatCountdown(countdown)}
                  </span>
                </div>
                <button
                  className="btn btn-primary"
                  onClick={updateData}
                  disabled={isLoading}
                >
                  {getIntervalTime(selectedTime) > 3600000
                    ? "Update Data"
                    : "Force Run Algorithms"}
                </button>
              </div>
            )}
            <div className="mt-4" style={{ whiteSpace: "pre-line" }}>
              {answer}
            </div>
            {selectedStock && (
              <StockAnalysisDisplay signalCounts={signalCounts} />
            )}

            {selectedStock && (
              <div className="chart-container">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={stockData}>
                    <XAxis dataKey="timekey" tickFormatter={formatXAxis} />
                    <YAxis domain={["auto", "auto"]} />
                    <Tooltip content={<CustomTooltip />} />
                    <Line
                      type="monotone"
                      dataKey="close"
                      stroke="#8884d8"
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )}

            {selectedStock && signals.length > 0 && (
              <SignalDetailedDisplay signals={signals} />
            )}
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="loading-overlay">
          <FaSpinner className="spinner" />
        </div>
      )}
    </Base>
  );
};

export default Algorithms;
