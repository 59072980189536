import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import NavBar from "./components/global/navBar";
import BottomBar from "./components/global/bottomBar";
import Home from "./views/other/home";
import About from "./views/other/about";
import Algorithms from "./views/user/algorithms";
import Login from "./views/user/login";
import Register from "./views/user/register";
import NotFound from "./views/other/notFound";
import Terms from "./views/other/terms";
import PrivacyPolicy from "./views/other/policy";
import FAQ from "./views/other/faq";
import ProtectedRoute from "./routes/ProtectedRoute";
import AdminRoute from "./routes/AdminRoute";
import AdminDashboard from "./views/admin/AdminDashboard";
import NewAlgorithms from "./views/user/new_algorithms";
import "antd/dist/reset.css";

const App = () => {
  return (
    <React.Fragment>
      <div id="root">
        <NavBar />
        <div className="content-wrapper">
          <div className="container mt-4">
            <div className="row">
              <div className="col-12">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/404" element={<NotFound />} />
                  <Route path="*" element={<Navigate replace to="/404" />} />
                  <Route
                    path="/algorithms"
                    element={
                      <ProtectedRoute>
                        <Algorithms />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/new_algorithms"
                    element={
                      <ProtectedRoute>
                        <NewAlgorithms />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/*"
                    element={
                      <AdminRoute>
                        <AdminDashboard />
                      </AdminRoute>
                    }
                  />
                </Routes>
              </div>
            </div>
          </div>
        </div>
        <BottomBar />
      </div>
    </React.Fragment>
  );
};

export default App;
