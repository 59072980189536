import React from "react";
import { Link } from "react-router-dom";
import Base from "../../components/global/base";

const About = () => {
  return (
    <Base>
      <div className="container mt-5">
        <h1 className="mb-4">About Shares Manager</h1>
        <div className="row">
          <div className="col-md-8">
            <h2>Our Story</h2>
            <p>
              Shares Manager is a passion project born from the collective
              interests and skills of a small team of ambitious software
              engineering students. United by our fascination with programming,
              algorithms, finance, and stock markets, we set out to create a
              platform that would make stock analysis more accessible and
              insightful for everyone.
            </p>
            <p>
              As part of LYC Consulting Group, we've poured our hearts and minds
              into developing this website, pushing the boundaries of our
              knowledge and skills to deliver a powerful tool for stock market
              enthusiasts and investors alike.
            </p>
            <h2 className="mt-4">Our Mission</h2>
            <p>
              We believe that with the right tools and information, anyone can
              make informed decisions in the stock market. Our mission is to
              democratize stock analysis by providing user-friendly, data-driven
              insights that were once only available to financial professionals.
            </p>
            <h2 className="mt-4">The Team</h2>
            <p>
              We're a diverse group of university students, each bringing unique
              skills and perspectives to the table. From front-end design to
              back-end algorithms, we've collaboratively built Shares Manager
              from the ground up, learning and growing with each line of code.
            </p>
            <div className="mt-4">
              <Link
                to="http://www.lycconsultant.com/"
                className="btn btn-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More About LYC Consulting Group
              </Link>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Quick Facts</h5>
                <ul className="list-unstyled">
                  <li>📍 Founded by university students</li>
                  <li>💡 Driven by passion for technology and finance</li>
                  <li>🚀 Constantly evolving and improving</li>
                  <li>🤝 Part of LYC Consulting Group</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default About;
