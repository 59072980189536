import React, { useState, useRef, useEffect } from "react";
import { FaRegHeart, FaHeart, FaTimes } from "react-icons/fa";
import "../../css/algorithms/Combobox.css";

const Combobox = ({
  fetchOptions,
  getOptionLabel,
  placeholder,
  debounceTime = 300,
  value,
  onChange,
  maxOptions = 10,
  onFollowToggle,
  isOptionFollowed,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const optionsListRef = useRef(null);
  const optionRefs = useRef({});
  const debounceTimerRef = useRef(null);

  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  const handleInput = async (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    onChange(null);
    setLoading(true);
    setShowOptions(true);
    setHighlightedIndex(-1);

    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    debounceTimerRef.current = setTimeout(async () => {
      try {
        const options = await fetchOptions(query);
        setFilteredOptions(options);
      } catch (error) {
        console.error("Error fetching options:", error);
        setFilteredOptions([]);
      } finally {
        setLoading(false);
      }
    }, debounceTime);
  };

  const selectOption = (option) => {
    setSearchQuery(getOptionLabel(option));
    setShowOptions(false);
    onChange(option);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setShowOptions(false);
      setHighlightedIndex(-1);
    }, 200);
  };

  const handleClear = () => {
    setSearchQuery("");
    onChange(null);
    setShowOptions(false);
    setFilteredOptions([]);
  };

  const handleKeyDown = (event) => {
    switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          Math.min(
            prevIndex + 1,
            Math.min(filteredOptions.length, maxOptions) - 1
          )
        );
        break;
      case "ArrowUp":
        event.preventDefault();
        setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, -1));
        break;
      case "Enter":
        event.preventDefault();
        if (highlightedIndex !== -1) {
          selectOption(filteredOptions[highlightedIndex]);
        }
        break;
      case "Escape":
        event.preventDefault();
        setShowOptions(false);
        setHighlightedIndex(-1);
        break;
      default:
      // do nothing
    }
  };

  useEffect(() => {
    if (highlightedIndex !== -1 && optionsListRef.current) {
      const highlighted = optionRefs.current[highlightedIndex];
      if (highlighted) {
        highlighted.scrollIntoView({
          block: "nearest",
          inline: "start",
        });
      }
    }
  }, [highlightedIndex]);

  const limitedOptions = filteredOptions.slice(0, maxOptions);

  return (
    <div className="combo-box-container">
      <div className="input-wrapper">
        <input
          type="text"
          className="combo-box-input"
          value={value ? getOptionLabel(value) : searchQuery}
          onChange={handleInput}
          onFocus={() => setShowOptions(true)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
        />
        {(value || searchQuery) && (
          <button className="clear-button" onClick={handleClear}>
            <FaTimes />
          </button>
        )}
        {value && (
          <div className="heart-icon-wrapper">
            <span
              className={`heart-icon ${
                isOptionFollowed(value) ? "followed" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                onFollowToggle(value);
              }}
            >
              {isOptionFollowed(value) ? <FaHeart /> : <FaRegHeart />}
            </span>
          </div>
        )}
      </div>
      {showOptions && limitedOptions.length > 0 && (
        <ul ref={optionsListRef} className="options-list">
          {limitedOptions.map((option, index) => (
            <li
              key={option.id}
              onClick={() => selectOption(option)}
              className={`option-item ${
                index === highlightedIndex ? "highlighted" : ""
              }`}
              ref={(el) => (optionRefs.current[index] = el)}
            >
              <span>{getOptionLabel(option)}</span>
              <span
                className={`heart-icon ${
                  isOptionFollowed(option) ? "followed" : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  onFollowToggle(option);
                }}
              >
                {isOptionFollowed(option) ? <FaHeart /> : <FaRegHeart />}
              </span>
            </li>
          ))}
        </ul>
      )}
      {loading && <div className="loading-message">Loading...</div>}
    </div>
  );
};

export default Combobox;
