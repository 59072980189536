import $ from 'jquery';
import API_BASE_URL from "../other/api_settings";

const getAuthHeader = () => {
  const token = localStorage.getItem("jwt_token");
  return token ? { Authorization: "Bearer " + token } : {};
};

export const getStockList = ({ page, pageSize, searchText, sortField, sortOrder }) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${API_BASE_URL}/api/admin/stocks`,
      type: "GET",
      headers: getAuthHeader(),
      data: {
        page,
        pageSize,
        searchText,
        sortField,
        sortOrder,
      },
      success(response) {
        // console.log(response);
        resolve(response);
      },
      error(error) {
        // console.log(error);
        reject(error);
      }
    });
  });
};

export const updateStockPopularity = (stockId, newPopularity) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${API_BASE_URL}/api/admin/stocks/${stockId}/popularity`,
      type: "PUT",
      headers: getAuthHeader(),
      data: JSON.stringify({ popularityRating: newPopularity }),
      contentType: "application/json",
      success(response) {
        resolve(response);
      },
      error(error) {
        reject(error);
      }
    });
  });
};

export const deleteStock = (stockId) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${API_BASE_URL}/api/admin/stocks/${stockId}`,
      type: "DELETE",
      headers: getAuthHeader(),
      success(response) {
        resolve(response);
      },
      error(error) {
        reject(error);
      }
    });
  });
};

export const searchStocks = (query) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${API_BASE_URL}/api/admin/stocks/search`,
      type: "GET",
      headers: getAuthHeader(),
      data: { query },
      success(response) {
        resolve(response);
      },
      error(error) {
        reject(error);
      }
    });
  });
};

export const getLastUpdatedTimes = (symbol) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${API_BASE_URL}/api/admin/stocks/${symbol}/last-updated`,
      type: "GET",
      headers: getAuthHeader(),
      success(response) {
        resolve(response);
      },
      error(error) {
        reject(error);
      }
    });
  });
};

export const addStock = (stockData) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${API_BASE_URL}/api/admin/stocks`,
      type: "POST",
      headers: getAuthHeader(),
      data: JSON.stringify(stockData),
      contentType: "application/json",
      success(response) {
        resolve(response);
      },
      error(error) {
        if (error.responseJSON && error.responseJSON.message) {
          reject(new Error(error.responseJSON.message));
        } else if (error.status === 409) {
          reject(new Error("Stock with this symbol already exists"));
        } else {
          reject(new Error("An error occurred while adding the stock"));
        }
      }
    });
  });
};