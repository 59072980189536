import React, { useState, useContext } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import Base from "../../components/global/base";
import { JSEncrypt } from "jsencrypt";
import { PUBLIC_KEY } from "../../services/other/crypto_settings_loader";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setIsLoading(true);

    try {
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(PUBLIC_KEY);
      const encryptedPassword = encrypt.encrypt(password);

      await login(username, encryptedPassword);
      const from = location.state?.from?.pathname || "/";
      navigate(from, { replace: true });
    } catch (error) {
      console.error("Login error:", error);
      setErrorMessage("Username or password is incorrect");
      setIsLoading(false);
    }
  };

  return (
    <Base>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6">
            <h2 className="mb-4">Welcome Back to Shares Manager</h2>
            <p className="lead">
              Log in to access your personalized stock management dashboard.
            </p>
            <ul className="list-group list-group-flush mb-4">
              <li className="list-group-item">View your followed stocks</li>
              <li className="list-group-item">
                Run advanced stock analysis algorithms
              </li>
              <li className="list-group-item">Get real-time market updates</li>
              <li className="list-group-item">
                Access your saved reports and insights
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <div className="card shadow">
              <div className="card-body">
                <h3 className="card-title text-center mb-4">
                  Log In to Your Account
                </h3>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="username" className="form-label">
                      Username
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder="Enter your username"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter your password"
                      required
                    />
                  </div>
                  {errorMessage && (
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  )}
                  <div className="d-grid">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Logging in...
                        </>
                      ) : (
                        "Log In"
                      )}
                    </button>
                  </div>
                </form>
                <p className="mt-3 text-center">
                  Don't have an account? <Link to="/register">Sign up</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default Login;
