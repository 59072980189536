// crypto_settings_local.jsx

export const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAhDJNpWSXYDvQGpE6z4I9
HAdbpKn7cS6u3K2M5UUINzZpI8b9AzOgH+ft6XfRseazkq3yXCAcSPTAcp2kJcLY
3kr7KZ9oxrOc+yDz4H7aR53pIzNTnMFHULMZBsbuAKUJLGF70sSce6++JWNmmGyV
0Zwc6JGQfe+10ed6GnDrMx2ey+NKko1FRe8R7BBiRF38/hP2HguLkQNyQpoLaHrf
Fhl8gqcaaDKizQugAH3HUQyAzY/Iv6g78udm1lbQG9pmqblYOks7BB6Y5syrJawl
j7u+cryyZWPUtp52w6kHacHP3o8n/k/t0w+e5yr5Tsyl+8/mcj+okZNVbSYqoYaa
sQIDAQAB
-----END PUBLIC KEY-----
`;

// You can add other crypto-related constants here if needed
