import React from "react";
import Base from "../../components/global/base";
import {
  FaDatabase,
  FaCog,
  FaShareAlt,
  FaLock,
  FaUserCog,
  FaClipboardList,
} from "react-icons/fa";

const PrivacyPolicy = () => {
  return (
    <Base>
      <div className="container mt-5">
        <h1 className="mb-4 text-center">Privacy Policy</h1>
        <p className="lead text-center mb-5">
          Last updated: {new Date().toLocaleDateString()}
        </p>

        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="card h-100">
              <div className="card-body text-center">
                <FaDatabase className="feature-icon mb-3" size={48} />
                <h3 className="card-title">Information We Collect</h3>
                <p className="card-text">
                  We collect information you provide directly to us, such as
                  when you create an account, use our services, or communicate
                  with us. This may include your name, email address, and usage
                  data.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="card h-100">
              <div className="card-body text-center">
                <FaCog className="feature-icon mb-3" size={48} />
                <h3 className="card-title">How We Use Your Information</h3>
                <p className="card-text">
                  We use the information we collect to provide, maintain, and
                  improve our services, to communicate with you, and to
                  personalize your experience.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="card h-100">
              <div className="card-body text-center">
                <FaShareAlt className="feature-icon mb-3" size={48} />
                <h3 className="card-title">Information Sharing</h3>
                <p className="card-text">
                  We do not share your personal information with third parties
                  except as described in this policy. We may share your
                  information with service providers who perform services on our
                  behalf.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-4 mb-4">
            <div className="card h-100">
              <div className="card-body text-center">
                <FaLock className="feature-icon mb-3" size={48} />
                <h3 className="card-title">Data Security</h3>
                <p className="card-text">
                  We take reasonable measures to help protect your personal
                  information from loss, theft, misuse, and unauthorized access,
                  disclosure, alteration, and destruction.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="card h-100">
              <div className="card-body text-center">
                <FaUserCog className="feature-icon mb-3" size={48} />
                <h3 className="card-title">Your Rights and Choices</h3>
                <p className="card-text">
                  You may update, correct, or delete your account information at
                  any time by logging into your account or contacting us.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="card h-100">
              <div className="card-body text-center">
                <FaClipboardList className="feature-icon mb-3" size={48} />
                <h3 className="card-title">Changes to This Policy</h3>
                <p className="card-text">
                  We may update this privacy policy from time to time. We will
                  notify you of any changes by posting the new privacy policy on
                  this page.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default PrivacyPolicy;
