import React from "react";

const Base = ({ children }) => {
  return (
    <div className="card base-card" style={{ marginTop: "20px" }}>
      <div className="card-body">{children}</div>
    </div>
  );
};

export default Base;
