import React, { Component } from "react";
import Base from "../../components/global/base";

class notFound extends Component {
  state = {};
  render() {
    return <Base> 404 Not Found </Base>;
  }
}

export default notFound;
