import React from "react";
import Base from "../../components/global/base";
import {
  FaHandshake,
  FaUserShield,
  FaBalanceScale,
  FaCopyright,
  FaExclamationTriangle,
  FaEdit,
} from "react-icons/fa";

const Terms = () => {
  return (
    <Base>
      <div className="container mt-5">
        <h1 className="mb-4 text-center">Terms of Service</h1>
        <p className="lead text-center mb-5">
          Last updated: {new Date().toLocaleDateString()}
        </p>

        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="card h-100">
              <div className="card-body text-center">
                <FaHandshake className="feature-icon mb-3" size={48} />
                <h3 className="card-title">Acceptance of Terms</h3>
                <p className="card-text">
                  By accessing and using Shares Manager, you accept and agree to
                  be bound by the terms and provision of this agreement.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="card h-100">
              <div className="card-body text-center">
                <FaUserShield className="feature-icon mb-3" size={48} />
                <h3 className="card-title">User Conduct</h3>
                <p className="card-text">
                  You agree to use Shares Manager only for lawful purposes and
                  in a way that does not infringe the rights of, restrict or
                  inhibit anyone else's use and enjoyment of the website.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="card h-100">
              <div className="card-body text-center">
                <FaCopyright className="feature-icon mb-3" size={48} />
                <h3 className="card-title">Intellectual Property</h3>
                <p className="card-text">
                  The content, organization, graphics, design, and other matters
                  related to Shares Manager are protected under applicable
                  copyrights and other proprietary laws.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-4 mb-4">
            <div className="card h-100">
              <div className="card-body text-center">
                <FaExclamationTriangle
                  className="feature-icon mb-3"
                  size={48}
                />
                <h3 className="card-title">Disclaimer of Warranties</h3>
                <p className="card-text">
                  Shares Manager is provided "as is" without warranty of any
                  kind, either express or implied, including, but not limited
                  to, the implied warranties of merchantability and fitness for
                  a particular purpose.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="card h-100">
              <div className="card-body text-center">
                <FaBalanceScale className="feature-icon mb-3" size={48} />
                <h3 className="card-title">Limitation of Liability</h3>
                <p className="card-text">
                  In no event shall Shares Manager or its suppliers be liable
                  for any damages whatsoever arising out of the use of or
                  inability to use the website.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="card h-100">
              <div className="card-body text-center">
                <FaEdit className="feature-icon mb-3" size={48} />
                <h3 className="card-title">Changes to Terms</h3>
                <p className="card-text">
                  We reserve the right to modify these terms at any time. Please
                  review these terms periodically for changes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default Terms;
